/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import module options
import NoteModuleListOptions from "data/Prod/NoteModuleList.json";

const AddNewNotePopup = ({
  afterPopupClose = () => {},
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  const { t, i18n } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const NoteModuleList = NoteModuleListOptions.map((item) => {
    return {
      value: item.value,
      label: t(item.label),
    };
  });

  const [selectedModuleType, setSelectedModuleType] = useState(null);
  const [noteModuleName, setNoteModuleName] = useState("");

  const [leadInput, setleadInput] = useState("");
  const [contactInput, setcontactInput] = useState("");
  const [opportunityInput, setOpportunityInput] = useState("");

  const [leadOptions, setleadOptions] = useState([]);
  const [contactOptions, setcontactOptions] = useState([]);
  const [opportunityOptions, setOpportunityOptions] = useState([]);

  const [noteLeadValue, setNoteLeadValue] = useState(null);
  const [noteLeadId, setNoteLeadId] = useState(null);
  const [noteContactValue, setNoteContactValue] = useState(null);
  const [noteContactId, setNoteContactId] = useState(null);
  const [noteContactPersonId, setNoteContactPersonId] = useState(null);
  const [noteOpportunityValue, setNoteOpportunityValue] = useState(null);
  const [noteOpportunityId, setNoteOpportunityId] = useState(null);

  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  // validation of invoice
  const [validation, setValidation] = useState({
    titleWarning: false,
    detailsWarning: false,
    typeWarning: false,
    moduleIdWarning: false,
  });

  //requirements of leads/contacts
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [emailAddress, setemailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [company, setcompany] = useState("");
  const [position, setposition] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");

  const [newLeadName, setnewLeadName] = useState("");
  const [newLeadEmail, setnewLeadEmail] = useState("");
  const [newLeadPhone, setnewLeadPhone] = useState("");

  //function for change oppotunity type
  const noteTypeSelectionHandler = (val) => {
    if (val) {
      setSelectedModuleType(val);
      setNoteModuleName(val.value);
    } else {
      setSelectedModuleType(null);
      setNoteModuleName("");
      setleadInput("");
      setcontactInput("");
      setleadOptions([]);
      setcontactOptions([]);
      setNoteLeadId(null);
      setNoteLeadValue(null);
      setNoteContactId(null);
      setNoteContactValue(null);
      setNoteContactPersonId(null);
    }
  };

  //function for change lead input
  const leadInputChangeHandler = (val) => {
    setleadInput(val);
  };

  //function for change opportuntiy input
  const opportunityInputHandler = (val) => {
    setOpportunityInput(val);
  };

  //function for change contact input
  const contactInputChangeHandler = (val) => {
    setcontactInput(val);
  };

  //function for get lead options
  const getLeadOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_LEAD_OPTIONS +
        `?token=${token}&leadstringinput=${leadInput}`;

      console.log("url of lead------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in lead------>", response);

      if (response.status) {
        setleadOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get contact options
  const getContactOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CONTACTS_OPTIONS +
        `?token=${token}&userstring=${contactInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setcontactOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get opportunity
  const getOpportunityOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVOICE_LIST_OPTIONS +
        `?token=${token}&invoicetitle=${opportunityInput}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setOpportunityOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change lead
  const leadSelectionHandler = (val) => {
    if (val) {
      setNoteLeadValue(val);
      setNoteLeadId(val.value);
      getLeadDetails(val.value);
    } else {
      setNoteLeadValue(null);
      setNoteLeadId(null);
      setleadInput("");
      setleadOptions([]);
    }
  };

  //function for change contact
  const contactSelectionHandler = (val) => {
    if (val) {
      setNoteContactValue(val);
      setNoteContactId(val._id);
      setNoteContactPersonId(val.value);
      getContactUserDetails(val.value);
    } else {
      setNoteContactValue(null);
      setNoteContactId(null);
      setNoteContactPersonId(null);
      setcontactInput("");
      setcontactOptions([]);
    }
  };

  //function for select opportunity
  const opportunitySelectionHandler = (val) => {
    if (val) {
      setNoteOpportunityValue(val);
      setNoteOpportunityId(val.value);
    } else {
      setNoteOpportunityValue(null);
      setNoteOpportunityId(null);
      setOpportunityInput("");
      setOpportunityOptions([]);
    }
  };

  // function for validation
  const validationHandler = () => {
    let isValid = true;

    if (selectedModuleType === null) {
      setValidation((prevState) => ({ ...prevState, typeWarning: true }));
      isValid = false;
    }

    if (selectedModuleType) {
      if (
        noteLeadValue === null &&
        noteContactValue === null &&
        noteOpportunityValue === null
      ) {
        setValidation((prevState) => ({
          ...prevState,
          moduleIdWarning: true,
        }));
        isValid = false;
      }
    }

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (description === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //* save the note record */
  const saveNote = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        let noteData = {
          notetype: "1",
          title,
          details: description,
          isprivate: isPrivate,
        };

        if (noteLeadId) {
          noteData.leadid = noteLeadId;
        }

        if (noteContactId) {
          noteData.contactid = noteContactId;
        }

        if (noteOpportunityId) {
          noteData.invoiceid = noteOpportunityId;
        }

        // if (taskId) {
        //   noteData.taskid = taskId;
        // }

        let requestUrl =
          url.API_BASE_URL + url.API_ADD_NEW_NOTE + `?token=${token}`;

        const response = await postData(requestUrl, noteData);

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#addNewNote")
          ).hide();

          resetHandler();
          afterPopupClose();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up all required fields");
      setMessageType("error");
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setValidation({
      titleWarning: false,
      detailsWarning: false,
      typeWarning: false,
      moduleIdWarning: false,
    });

    setSelectedModuleType(null);
    setNoteModuleName("");
    setleadInput("");
    setcontactInput("");
    setOpportunityInput("");
    setleadOptions([]);
    setcontactOptions([]);
    setOpportunityOptions([]);
    setNoteLeadId(null);
    setNoteLeadValue(null);
    setNoteContactId(null);
    setNoteContactValue(null);
    setNoteContactPersonId(null);
    setNoteOpportunityValue(null);
    setNoteOpportunityId(null);

    setnewLeadName("");
    setnewLeadEmail("");
    setnewLeadPhone("");

    settitle("");
    setdescription("");

    //reset information
    setfirstName("");
    setlastName("");
    setemailAddress("");
    setPhoneNumber("");
    setDateOfBirth("");
    setcompany("");
    setposition("");
    setcity("");
    setstate("");
    setcountry("");
    setzipcode("");
  };

  //function for get lead details
  const getLeadDetails = async (leadId = null) => {
    if (leadId) {
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_GET_LEAD_DETAILS +
          `/${leadId}?token=${token}`;

        console.log("url of lead details------>", requestUrl);

        const response = await getData(requestUrl);

        console.log("response of lead details------>", response);

        if (response.status) {
          setfirstName(response.data.name);
          setlastName(response.data.surname);
          setemailAddress(response.data.email);
          setPhoneNumber(response.data.phone);

          if (response.data.dob) {
            if (response.data.dob.includes("-")) {
              let components = response.data.dob.split("-");
              let year = components[0];
              let month = components[1];
              let day = components[2];
              let newDate = day + "-" + month + "-" + year;
              setDateOfBirth(newDate);
            }
          } else {
            setDateOfBirth("");
          }

          setcompany(response.data?.company ?? "");
          setposition(response.data?.position ?? "");
          setcity(response.data?.city ?? "");
          setstate(response.data?.state ?? "");
          setcountry(response.data?.country ?? "");
          setzipcode(response.data?.zipcode ?? "");
          setaddress(response.data?.address ?? "");
        } else {
          setMessageType("error");
          setAlertMessage(response.message);
          setShowAlert(true);
        }
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
        setShowAlert(true);
      }
    }
  };

  //function for get contact user details
  const getContactUserDetails = async (userId = null) => {
    // console.log("userInfo", userInfo);
    if (userId) {
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_GET_USER_DETAILS +
          `/${userId}` +
          `?token=${token}`;

        // console.log("requestUrl", requestUrl);

        const response = await getData(requestUrl);

        // console.log(response);

        if (response.status && response.data) {
          setfirstName(response.data.name);
          setlastName(response.data.surname);
          setemailAddress(response.data.email);
          setPhoneNumber(response.data.phone);

          if (response.data.dob) {
            let components = response.data.dob.split("-");
            let year = components[0];
            let month = components[1];
            let day = components[2];
            let newDate = day + "-" + month + "-" + year;
            setDateOfBirth(newDate);
          } else {
            setDateOfBirth("");
          }

          setcompany(response.data?.company ?? "");
          setposition(response.data?.position ?? "");
          setcity(response.data?.city ?? "");
          setstate(response.data?.state ?? "");
          setcountry(response.data?.country ?? "");
          setzipcode(response.data?.zipcode ?? "");
          setaddress(response.data?.address ?? "");
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    if (leadInput.length > 0) {
      getLeadOptions();
    }
  }, [leadInput]);

  useEffect(() => {
    if (contactInput.length > 0) {
      getContactOptions();
    }
  }, [contactInput]);

  useEffect(() => {
    if (opportunityInput.length > 0) {
      getOpportunityOptions();
    }
  }, [opportunityInput]);

  return (
    <div
      className="offcanvas invoice_w offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="addNewNote"
      aria-labelledby="addNewNote"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          Add New Note
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <div className="row">
          <div
            //   className="col-lg-8"
            className="col-lg-12"
          >
            <div className="p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="d-flex flex-column h-100"
              >
                <div className="fields-container flex-fill">
                  {/* <!-- ========== Start opportunity type Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="labelName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Module")}
                    </label>
                    <Select
                      isClearable
                      placeholder="Select Module"
                      options={NoteModuleList}
                      value={selectedModuleType}
                      onChange={(val) => {
                        noteTypeSelectionHandler(val);
                        setValidation((prevState) => ({
                          ...prevState,
                          typeWarning: false,
                        }));
                      }}
                    />
                    {validation.typeWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>Please select module!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <!-- ========== End opportunity type Section ========== --> */}

                  {/* <!-- ========== Start opportunity lead/contact Section ========== --> */}
                  {selectedModuleType ? (
                    <div className="form-group mb-4">
                      <label
                        htmlFor="labelName"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Select Component")}
                      </label>
                      {selectedModuleType?.value === "lead" ? (
                        <Select
                          isClearable
                          placeholder="Type Lead name or email"
                          options={leadOptions}
                          value={noteLeadValue}
                          onChange={(val) => {
                            leadSelectionHandler(val);
                            setValidation((prevState) => ({
                              ...prevState,
                              moduleIdWarning: false,
                            }));
                          }}
                          onInputChange={(val) => {
                            leadInputChangeHandler(val);
                            setValidation((prevState) => ({
                              ...prevState,
                              moduleIdWarning: false,
                            }));
                          }}
                        />
                      ) : selectedModuleType?.value === "opportunity" ? (
                        <Select
                          isClearable
                          placeholder="Type opportuntiy title"
                          options={opportunityOptions}
                          value={noteOpportunityValue}
                          onChange={(val) => {
                            opportunitySelectionHandler(val);
                            setValidation((prevState) => ({
                              ...prevState,
                              moduleIdWarning: false,
                            }));
                          }}
                          onInputChange={(val) => {
                            opportunityInputHandler(val);
                            setValidation((prevState) => ({
                              ...prevState,
                              moduleIdWarning: false,
                            }));
                          }}
                        />
                      ) : (
                        <Select
                          isClearable
                          placeholder="Type contact name"
                          options={contactOptions}
                          value={noteContactValue}
                          onChange={(val) => {
                            contactSelectionHandler(val);
                            setValidation((prevState) => ({
                              ...prevState,
                              moduleIdWarning: false,
                            }));
                          }}
                          onInputChange={(val) => {
                            contactInputChangeHandler(val);
                            setValidation((prevState) => ({
                              ...prevState,
                              moduleIdWarning: false,
                            }));
                          }}
                        />
                      )}

                      {validation.moduleIdWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>
                              Please select component with required fields!
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  ) : null}
                  {/* <!-- ========== End opportunity lead/contact Section ========== --> */}

                  {/* <!-- ========== Start title Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="labelName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter Title")}
                      value={title}
                      onChange={(e) => {
                        settitle(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          titleWarning: false,
                        }));
                      }}
                    />
                    {validation.titleWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>Please enter a title!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <!-- ========== End title Section ========== --> */}

                  {/* <!-- ========== Start description Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="description"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Description
                    </label>
                    <textarea
                      name=""
                      id=""
                      rows="5"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter Details")}
                      value={description}
                      onChange={(e) => {
                        setdescription(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          detailsWarning: false,
                        }));
                      }}
                    ></textarea>
                    {validation.detailsWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>Please enter details!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <!-- ========== End description Section ========== --> */}

                  {/* <!-- ========== Start is private checkbox Section ========== --> */}
                  <div className="form-group d-flex align-items-center gap-2 mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input check-success border-primary"
                        type="checkbox"
                        checked={isPrivate}
                        onChange={(e) => setIsPrivate(e.target.checked)}
                      />
                    </div>

                    <span className="d-block fs-sm fw-semibold">
                      {t("Mark as Private")}
                    </span>
                  </div>
                  {/* <!-- ========== End is private checkbox Section ========== --> */}
                </div>

                <div className="action d-flex justify-content-between gap-3">
                  <Link
                    to="#"
                    className="btn btn-outline-primary"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={resetHandler}
                  >
                    {t("Close")}
                  </Link>
                  <button
                    onClick={saveNote}
                    type="button"
                    className="btn btn-primary"
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Add New Note")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-lg-4">
            {invoiceLeadValue || invoiceContactValue ? (
              <div className="p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <h2>
                  {invoiceLeadId && "Lead"}{" "}
                  {invoiceContactPersonId && "Contact"} Information
                </h2>

                <h3>Name</h3>
                <p>
                  <Link
                    to={
                      invoiceLeadId
                        ? `/admin/crm/lead/information/${invoiceLeadId}`
                        : invoiceContactId
                        ? `/admin/crm/contact/details/timeline/${invoiceContactId}`
                        : "#"
                    }
                  >
                    {firstName} {lastName}
                  </Link>
                </p>

                <h3>Email</h3>
                <p>{emailAddress}</p>

                <h3>Phone</h3>
                <p>{phoneNumber}</p>

                <h3>Date of birth</h3>
                <p>{dateOfBirth}</p>

                <h3>Position</h3>
                {position === "" ? null : (
                  <p>
                    {position} {position !== "" && company !== "" ? "at" : ""}{" "}
                    {company === "" ? "" : company}
                  </p>
                )}

                <h3>Location</h3>
                <p>{address}</p>

                <h3>City</h3>
                <p>{city}</p>

                <h3>State</h3>
                <p>{state}</p>

                <h3>Country</h3>
                <p>{country}</p>

                <h3>Zipcode</h3>
                <p>{zipcode}</p>
              </div>
            ) : null}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddNewNotePopup;
